import { createSelector } from '@ngrx/store';
import { ConfigStatic } from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { getLocationsState } from '@shared/state/locations/selectors';
import { getLocationFilters } from '@shared/state/locationsFilters/selectors';
import { getAvailablePickupTimesForAllLocations } from '@shared/state/availablePickups/selectors';
import { getGeolocationState } from '@shared/state/geolocation/selectors';
import { getOrderTypeId } from '@shared/state/collectionType/selectors';

const sortFilteredLocation = (a: OLO.DTO.OnlineOrderingLocationBusinessModel, b: OLO.DTO.OnlineOrderingLocationBusinessModel, nameOfProperty: string) => {
    const aD = a[nameOfProperty];
    const bD = b[nameOfProperty];

    switch (true) {
        case aD === bD:
            return 0;
        case aD < bD:
            return -1;
        case aD > bD:
            return 1;
    }
};

const sortFunc = (a: OLO.DTO.OnlineOrderingLocationBusinessModel, b: OLO.DTO.OnlineOrderingLocationBusinessModel, sortTag: string) => {
    const aTag = !!a.LocationTags.find((tag) => tag.TagName === sortTag);
    const bTag = !!b.LocationTags.find((tag) => tag.TagName === sortTag);

    switch (true) {
        case aTag && bTag:
            return 0;
        case aTag && !bTag:
            return -1;
        case !aTag && bTag:
            return 1;
    }
};

export const getFilteredLocations = (sortTag: string = null, filterParams: OLO.Common.LocationFilterParams = {}) =>
    createSelector(
        getLocationsState,
        getLocationFilters,
        getAvailablePickupTimesForAllLocations,
        getGeolocationState,
        getOrderTypeId,
        (locations, filters, availablePickups, geoLocationState, orderTypeId) => {
            if (!locations.data.length || locations.isDownloading || locations.hasFailed) return [];

            let clientTime: Date | string = new Date();
            const config = new ConfigStatic().current;
            const isMetric = config.localization?.units === 'metric';
            const radius = isMetric ? config.google?.maps?.searchDistance : config.google?.maps?.searchDistance * 1.609344 || null;
            const filterBy: OLO.Common.LocationFilterParams = {
                bySearch: true,
                byOpenStatus: false,
                byPickupTime: false,
                byDeliveryRadius: true,
                byCollectionType: true,
                ...filterParams,
            };
            const isFutureSearch = filters.pickupTime ? filters.pickupTime.IsToday === false : false;
            const filtered = locations.data.filter((location) => {
                const isMetricLocationUnits = location.DeliveryRadiusUnitType === 1;
                if (!location.LocationFriendlyName || !location.OrderTypes.length || !location.LocationOLOIsActive || location.EOIPriceLevelID === 0) return false;

                const collectionTypesInfo = new Utils.LocationCollectionTypesChecker(location, config);
                if (!collectionTypesInfo.hasAnyTypes()) {
                    return false;
                }

                if (filterBy.byCollectionType && orderTypeId) {
                    const selectedCollectionType = new Utils.CollectionTypeGroupDetector(orderTypeId, config);
                    const foundMatch = location.OrderTypes.reduce((match, nextOrder) => {
                        if (match) {
                            return true;
                        }

                        return selectedCollectionType.matchOrderTypeToCollectionType(nextOrder.Id);
                    }, false);

                    if (!foundMatch) return false;
                }

                const orderInfoForLocation = Utils.Pickups.getFilteredOrderingTimeInfo(location, orderTypeId);
                if (!orderInfoForLocation) return false;

                const dateToSearch = (filters.pickupTime && filters.pickupTime.DateLocalISO) || Utils.Dates.getLocalISOFormatDate(new Date());
                const timeInfo: OLO.DTO.LocationOrderingTimeInfoModel = orderInfoForLocation.find(Utils.Pickups.datesMatchByDayCallback(dateToSearch));
                if (!timeInfo && filterBy.byPickupTime) return false;

                /* Check hours - if no filters - compare to current time, else, get time from filters */
                if (filterBy.byPickupTime && filters.pickupTime) {
                    clientTime = filters.pickupTime.DateLocalISO;
                }

                const locationPickupTimesObj = availablePickups.find((obj) => obj.locationNo === location.LocationNo && obj.hasSucceeded === true && obj.data.length > 0);
                const locationPickupTimesList: OLO.Ordering.PickupTime[] = locationPickupTimesObj ? locationPickupTimesObj.data : [];
                const locationAsapObj: OLO.Ordering.PickupTime = locationPickupTimesList[0] || null;
                const openTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.OpeningTime) : null;
                const closeTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.ClosingTime) : null;

                /* AOLO-273 - ASAP - if location is still open and preperation doesn't exceed location open time */
                if (filterBy.byPickupTime) {
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        if (locationAsapObj) {
                            const preperationTime: number = Utils.Dates.createHoursIntFromDate(locationAsapObj.Id + locationAsapObj.MinutesFromNow * 60 * 1000);

                            if (preperationTime >= closeTime) return false;
                        }
                    }

                    /* AOLO-276 - validate open status when filters is set to ASAP */
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        const isLocationOpen: boolean = Utils.Dates.isHourInHoursRange(
                            Utils.Dates.getLocalISOFormatDate(new Date()),
                            timeInfo.OpeningTime,
                            timeInfo.ClosingTime,
                            'from',
                        );

                        if (!isLocationOpen) {
                            return false;
                        }
                    }
                }

                if (filterBy.byOpenStatus) {
                    const timeToCheck: number = Utils.Dates.createHoursIntFromDate(clientTime);

                    if (timeToCheck < openTime || timeToCheck > closeTime) {
                        return false;
                    }
                }

                if (filterBy.bySearch) {
                    /* Geocoder filter is data returned from google maps api - it is NOT related to browser geolocation */
                    if (filters.geocoder) {
                        if (!radius || !location?.Latitude || !location?.Longitude) {
                            return false;
                        }

                        const distance = Utils.Geolocation.getDistanceFromTo(
                            {
                                latitude: filters.geocoder.lat,
                                longitude: filters.geocoder.lng,
                            },
                            {
                                latitude: location.Latitude,
                                longitude: location.Longitude,
                            },
                        );

                        location.Distance = distance;

                        return distance <= radius;
                    } else {
                        let searchMatch: boolean = true;
                        if (filters.search) {
                            const searchString: string = filters.search.toLowerCase();
                            const _s = (fieldName: string) => (location[fieldName] !== null ? location[fieldName].toLowerCase().includes(searchString) : false);

                            searchMatch = _s('LocationFriendlyName') || _s('LocationDescription') || _s('StreetAddress') || _s('Suburb') || _s('PostCode') || _s('LocationNotes');
                        }
                        if (!searchMatch) return false;
                    }
                }

                if (filterBy.byDeliveryRadius) {
                    if (!location.DeliveryRadius || !location?.Latitude || !location?.Longitude) {
                        return false;
                    }

                    // TODO UPDATE DIAGRAM WITH NEW CONDITION AND REMOVED FROM 170 LINE !filters?.address?.geometry
                    if (filters?.address?.geometry) {
                        const distance = Utils.Geolocation.getDistanceFromTo(
                            {
                                latitude: filters.address.geometry.lat,
                                longitude: filters.address.geometry.lng,
                            },
                            {
                                latitude: location.Latitude,
                                longitude: location.Longitude,
                            },
                        );

                        location.Distance = isMetricLocationUnits ? distance : distance * 1.609344;

                        const isLocationInAddressRadius = distance <= location.DeliveryRadius;
                        if (!isLocationInAddressRadius) {
                            return false;
                        }
                    }
                }

                if (filterBy.byPickupTime) {
                    const availablePickup = availablePickups.find((pickup) => pickup.locationNo === location.LocationNo && pickup.hasSucceeded === true && pickup.data !== null);
                    if (!isFutureSearch && (!availablePickup || !availablePickup.data || availablePickup.data.length === 0)) {
                        return false;
                    }

                    const { FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead } = Utils.LocationFutureOrdering.getLocationFutureOrderingDetails({ location, orderTypeId });
                    const isLocationConfiguredForFutureOrder = FutureOrderingMinDaysAhead !== null && FutureOrderingMaxDaysAhead !== null;
                    if (!isFutureSearch && !isLocationConfiguredForFutureOrder) {
                        if (!filters.pickupTime) return true; /* Pure ASAP */

                        return availablePickup.data.some((pickup) => pickup.Id === filters.pickupTime.Id);
                    }

                    return Utils.Pickups.isFuturePickupTimeValid(filters.pickupTime || null, timeInfo, FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead);
                }

                /* Geolocation is used when it is allowed to use by user and google maps api didn't return data or it is not configured */
                if (geoLocationState.data && (!sortTag || !filters.geocoder) && radius) {
                    const distance = Utils.Geolocation.getDistanceFromTo(
                        {
                            latitude: geoLocationState.data.latitude,
                            longitude: geoLocationState.data.longitude,
                        },
                        {
                            latitude: location.Latitude,
                            longitude: location.Longitude,
                        },
                    );

                    if (distance > radius) {
                        return false;
                    }
                }

                return true;
            });

            if (sortTag === 'Distance') {
                filtered
                    .sort((a, b) => sortFilteredLocation(a, b, sortTag))
                    .map((location) => {
                        delete location.Distance;

                        return { ...location };
                    });
            }

            return filtered.sort((a, b) => sortFunc(a, b, sortTag));
        },
    );
